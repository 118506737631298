import * as React from "react"
import "react-confirm-alert/src/react-confirm-alert.css"
import "./../style/common.scss"
import { CommonLayout } from "../common/common-layout/common-layout"
import { VacanciesPage } from "../page-components/vacancies/vacancies-page"

export default function Vacancies(props) {
  return (
    <CommonLayout {...props}>
      <VacanciesPage/>
    </CommonLayout>
  )
}
