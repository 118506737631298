// extracted by mini-css-extract-plugin
export var section = "hiring-process-module--section--4rB1-";
export var container = "hiring-process-module--container--+FhM6";
export var heading = "hiring-process-module--heading--cR2dC";
export var title = "hiring-process-module--title--Se2Ej";
export var description = "hiring-process-module--description--XcBx5";
export var slider = "hiring-process-module--slider--uvTSO";
export var sliderNavContainer = "hiring-process-module--slider-nav-container--W1ul6";
export var prev = "hiring-process-module--prev--piAv1";
export var next = "hiring-process-module--next--B+hkW";
export var sliderNav = "hiring-process-module--slider-nav--Kn2Jt";
export var buttonDisabled = "hiring-process-module--button-disabled--xYJja";
export var buttonActive = "hiring-process-module--button-active--as3ty";
export var hiringProcessCard = "hiring-process-module--hiring-process-card--kMq5g";
export var cardHeading = "hiring-process-module--card-heading--Pc84s";
export var line = "hiring-process-module--line--Vt4Kv";
export var visible = "hiring-process-module--visible--N5jil";
export var hidden = "hiring-process-module--hidden--PiE8V";
export var long = "hiring-process-module--long--rckZ2";
export var short = "hiring-process-module--short--OE7d6";
export var cardTitle = "hiring-process-module--card-title--v1sSm";
export var cardDescription = "hiring-process-module--card-description--SSQl+";
export var pink = "hiring-process-module--pink--zHqCI";
export var violet = "hiring-process-module--violet--crXNf";
export var orange = "hiring-process-module--orange--e6+El";