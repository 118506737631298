import { useTranslation } from "../../common/use-translations/use-translations"
import { Helmet } from "react-helmet"
import * as React from "react"
import { VacanciesScreen } from "./vacancies-screen/vacancies-screen"
import { JoinUsScreen } from "../../common/join-us-screen/join-us-screen"
import { HiringProcess } from "./hiring-process/hiring-process"
import { RecommendationScreen } from "./recommendation-screen/recommendation-screen"

export function VacanciesPage() {
  const _ = useTranslation()
  return (
    <>
      <Helmet>
        <title>{_.vacancies} - OBRIO</title>
      </Helmet>
      <VacanciesScreen />
      <RecommendationScreen />
      <HiringProcess />
    </>
  )
}
