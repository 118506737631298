import * as React from "react"
import * as css from "./hiring-process.module.scss"
import classNames from "classnames"
import { TimelineCard } from "../../../common/timeline-card/timeline-card"
import { HiringProcessCard } from "./hiring-process-card"
import { useTranslation } from "../../../common/use-translations/use-translations"
import { SwiperSlide, Swiper } from "swiper/react"
import { useState } from "react"
import { Icon } from "../../../common/icon/icon"

export const HiringProcess = () => {
  const _ = useTranslation()
  // @ts-ignore
  const [slider, setSlider] = useState<Swiper>();
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  return (
    <section className={css.section}>
      <div className={classNames("container", css.container)}>
        <div className={css.heading}>
          <h2 className={css.title}>{_.hiring_process_t}</h2>
          <p className={css.description}>{_.hiring_process_d}</p>
        </div>
        <Swiper
          onSlideChange={() => {
            setIsBeginning(slider?.isBeginning);
            setIsEnd(slider?.isEnd);
          }}
          className={css.slider}
          onInit={swiper => setSlider(swiper)}
          loop={false}
          speed={700}
          slidesPerView={3}
          spaceBetween={16}
          breakpoints={{
            320: {
              slidesPerGroup: 1,
              slidesPerView: 1,
              
            },
            768: {
              slidesPerGroup: 2,
              slidesPerView: 2,
            },
            1024: {
              slidesPerGroup: 3,
              slidesPerView: 3,
            },
          }}
        >
          <SwiperSlide className={css.slide} >
            <TimelineCard className={classNames(css.cardVacancies)} >
              <HiringProcessCard
                image="/deco/cv-violet.svg"
                time={"1 " + _.day}
                title={_.day_1_t}
                description={_.day_1_d}
                color="violet"
                line="visible"
                lineLength="long"
              />
            </TimelineCard>
          </SwiperSlide>
          <SwiperSlide className={css.slide} >
            <TimelineCard className={classNames(css.cardVacancies)} >
              <HiringProcessCard
                image="/deco/cv-pink.svg"
                time={"1 " + _.day}
                title={_.day_1_1_t}
                description={_.day_1_1_d}
                color="pink"
                line="visible"
                lineLength="long"
              />
            </TimelineCard>
          </SwiperSlide>
          <SwiperSlide className={css.slide} >
            <TimelineCard className={classNames(css.cardVacancies)} >
              <HiringProcessCard
                image="/deco/cv-orange.svg"
                time={"2-3 " + _.days}
                title={_.days_2_3_t}
                description={_.days_2_3_d}
                color="orange"
                line="visible"
                lineLength="long"
              />
            </TimelineCard>
          </SwiperSlide>
          <SwiperSlide className={css.slide} >
            <TimelineCard className={classNames(css.cardVacancies)} >
              <HiringProcessCard
                image="/deco/cv-pink.svg"
                time={"1-2 " + _.days}
                title={_.days_1_2_t}
                description={_.days_1_2_d}
                color="pink"
                line="visible"
                lineLength="long"
              />
            </TimelineCard>
          </SwiperSlide>
          <SwiperSlide className={css.slide} >
            <TimelineCard className={classNames(css.cardVacancies)} >
              <HiringProcessCard
                image="/deco/cv-violet.svg"
                time={"0 " + _.days_2}
                title={_.days_0_t}
                description={_.days_0_d}
                color="violet"
                line="visible"
                lineLength="short"
              />
            </TimelineCard>
          </SwiperSlide>
          <SwiperSlide className={css.slide} >
            <TimelineCard className={classNames(css.cardVacancies)} >
              <HiringProcessCard
                image="/deco/cv-star.svg"
                time={"0 " + _.days_2}
                title={_.days_0_0_t}
                description={_.days_0_0_d}
                color="orange"
                line="hidden"
                lineLength="long"
              />
            </TimelineCard>
          </SwiperSlide>
        </Swiper>

        <div className={css.sliderNavContainer}>
          <button
            className={`${css.sliderNav} ${css.prev} ${isBeginning ? css.buttonDisabled : css.buttonActive}`}
            onClick={() => slider.slidePrev()}
          >
            <Icon name="chevron-right" />
          </button>
          <button
            className={`${css.sliderNav} ${css.next} ${isEnd ? css.buttonDisabled : css.buttonActive}`}
            onClick={() => slider.slideNext()}
          >
            <Icon name="chevron-right" />
          </button>
        </div>
      </div>
    </section>
  )
}
