import * as React from "react"
import * as css from "./recommendation-screen.module.scss"
import { LinkButton } from "../../../common/button/link-button"
import { Button } from "../../../common/button/button"
import { confirmAlert } from "react-confirm-alert"
import { CvPopup } from "../cv-popup/cv-popup"
import { SocialNetworkUrl } from "../../../constants/social-network-url"
import { useTranslation } from "../../../common/use-translations/use-translations"
import classNames from "classnames"

export const RecommendationScreen = () => {
  const _ = useTranslation()
  return (
    <section className={css.section}>
      <div className="container">
        <div className={css.text}>
          <h2
            className={css.title}
            dangerouslySetInnerHTML={{ __html: _.recommendation_t }}
          ></h2>
          <div
            className={css.description}
            dangerouslySetInnerHTML={{ __html: _.recommendation_d }}
          />
        </div>

        <div className={css.actions}>
          <div className={css.actionsButtons}>
            {/* TODO Delete div with emptyButton class name. This button was added because of problems on the server side to handle request with cv form
            <Button
              className={css.button}
              onClick={e => {
                e.preventDefault()
                confirmAlert({
                  overlayClassName: "popup-overlay",
                  customUI: ({ onClose }) => (
                    <CvPopup onClose={onClose} translates={_} />
                  ),
                })
              }}
            >
              {_.write_us}
            </Button> */}
            <LinkButton
              className={classNames(css.button, "telegram-link-button")}
              url={SocialNetworkUrl.Telegram}
              type="border"
              blank={true}
            >
              {_.telegram}
              <img src="/deco/plane.svg" alt="" />
            </LinkButton>
            <div className={css.emptyButton} />
          </div>
          <div className={css.ta}>
            <div className={css.backgroundTa}>
              <picture>
                <source media="(max-width: 768px)" srcSet="/ta-mobile.png" />
                <source media="(max-width: 1024px)" srcSet="/ta-tb.png" />
                <img className={css.backgroundTaImage} src="/ta-desktop.png" alt="talant acqusition team" />
              </picture>
              <div className={css.arrow} />
            </div>
            <div className={css.taName}>Talent Acquisition Team</div>
          </div>
        </div>
      </div>
    </section>
  )
}
