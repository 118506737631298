// extracted by mini-css-extract-plugin
export var section = "vacancies-screen-module--section--WWqFx";
export var vacancies = "vacancies-screen-module--vacancies--o-w8A";
export var title = "vacancies-screen-module--title--ohzUZ";
export var vacanciesList = "vacancies-screen-module--vacancies-list--iDp-0";
export var vacancy = "vacancies-screen-module--vacancy--ET984";
export var img = "vacancies-screen-module--img--yrulB";
export var vacancyTitle = "vacancies-screen-module--vacancy-title--kAYUa";
export var arrow = "vacancies-screen-module--arrow--F1ifk";
export var schedule = "vacancies-screen-module--schedule--2Mi+i";
export var button = "vacancies-screen-module--button--oRpQD";
export var buttonContainer = "vacancies-screen-module--button-container--5s5or";