import * as React from "react"
import * as css from "./vacancies-screen.module.scss"
import { VacanciesList } from "./vacancies-list"
import { LinkButton } from "../../../common/button/link-button"
import { useTranslation } from "../../../common/use-translations/use-translations"
import classNames from "classnames"

export const VacanciesScreen = () => {
  const _ = useTranslation()
  return (
    <section className={css.section}>
      <div className="container">
        <div className={css.vacancies}>
          <h1 className={css.title}>{_.our_vacancies}</h1>
          <VacanciesList />
          <div className={css.buttonContainer}>
          <LinkButton
            className={classNames(css.button, 'other-vacancies')}
            type="primary"
            blank={true}
            url="https://obrio-76be502da02f.breezy.hr/"
          >
            {_.other_vacancies}
          </LinkButton>
          </div>
        </div>
      </div>
    </section>
  )
}
