// extracted by mini-css-extract-plugin
export var section = "recommendation-screen-module--section--VcSbR";
export var text = "recommendation-screen-module--text--eEcVr";
export var title = "recommendation-screen-module--title--Y+Zei";
export var description = "recommendation-screen-module--description--VK6W2";
export var actions = "recommendation-screen-module--actions--9BfDk";
export var actionsButtons = "recommendation-screen-module--actions-buttons--ozI-E";
export var button = "recommendation-screen-module--button--ZZyQT";
export var emptyButton = "recommendation-screen-module--emptyButton--RyBEz";
export var ta = "recommendation-screen-module--ta--VAiEm";
export var taName = "recommendation-screen-module--ta-name--BqCf8";
export var taPosition = "recommendation-screen-module--ta-position--653aS";
export var backgroundTa = "recommendation-screen-module--backgroundTa--iBom0";
export var backgroundTaImage = "recommendation-screen-module--backgroundTaImage--JhWZl";
export var arrow = "recommendation-screen-module--arrow--iXe3o";