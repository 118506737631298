import * as React from "react"
import * as css from "./hiring-process.module.scss"
import classNames from "classnames"

export const HiringProcessCard = (props: {
  image: string
  time: string
  title: string
  description: string
  color: "pink" | "violet" | "orange"
  line: "visible" | "hidden"
  lineLength: "long" | "short"
}) => {

  const classMap : Record<"pink" | "violet" | "orange", string> = {
    pink: css.pink,
    violet: css.violet,
    orange: css.orange,
  }

  const lineMap: Record<"visible" | "hidden", string> = {
    visible: css.visible,
    hidden: css.hidden,
  }

  const lineLengthMap: Record<"long" | "short", string> = {
    long: css.long,
    short: css.short,
  }

  return (
    <div className={css.hiringProcessCard}>
      <div className={css.cardHeading}>
        <img src={props.image} alt="" />
        <span className={classMap[props.color]}>{props.time}</span>
          <span className={classNames(
            css.line,
            lineMap[props.line],
            lineLengthMap[props.lineLength]
          )}></span>
      </div>
      <div>
        <div className={css.cardTitle} dangerouslySetInnerHTML={{ __html: props.title }}></div>
        <p className={css.cardDescription}>{props.description}</p>
      </div>
    </div>
  )
}
